<template>
  <AdminView>
    <h1 class="title-1">Prescripteurs</h1>

    <div class="column">
      <PrescriberForm v-for="(prescriber, i) in prescribers" :key="i"
        :prescriber="prescriber"
      />
    </div>

    <div class="buttons-container">
      <Button
        title="Ajouter un prescripteur"
        type="primary"
        @click="addPrescriber()"
      >
        <SVGPlus />
      </Button>
    </div>
  </AdminView>
</template>

<script>
import AdminView from '@/components/AdminView'
import PrescriberForm from '@/components/Forms/PrescriberForm'
import Button from '@/components/Button'
import SVGPlus from '@/assets/icons/plus.svg'

export default {
  name: 'PrescribersList',
  components: {
    AdminView, PrescriberForm, Button, SVGPlus
  },
  data () {
    return {
      prescribers: [
        {
          firstname: 'John',
          lastname: 'Doe',
          email: 'john.doe@latelier.co',
          phone: '0600000000',
          location: 'Paris',
          sendData: true
        },
        {
         firstname: 'John',
          lastname: 'Doe',
          email: 'john.doe@latelier.co',
          phone: '0600000000',
          location: 'Paris',
          sendData: false
        }
      ]
    }
  },
  methods: {
    addPrescriber () {
      this.prescribers.push({})
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons-container{
  display: flex;
  justify-content: center;
  margin: 20px 0;

  div {
    flex: 0;
  }
}
</style>
