<template>
  <div class="row form">
    <div class="column fields">
      <div class="row">
        <Input
          type="text"
          title="Nom"
          v-model="prescriber.lastname"
        />

        <Input
          type="text"
          title="Prénom"
          v-model="prescriber.firstname"
        />

        <Input
          type="text"
          title="Mail"
          v-model="prescriber.email"
        />
      </div>

      <div class="row">
        <Input
          type="text"
          title="Téléphone"
          v-model="prescriber.phone"
        />

        <Input
          type="text"
          title="Agence / Secteur"
          v-model="prescriber.location"
        />

        <SwitchButton
          title="Envoi des données"
          v-model="prescriber.sendData"
        />
      </div>
    </div>

    <div class="column buttons">
      <Button
        title="Modifier"
        type="secondary"
      />
      <Button
        title="Supprimer"
        type="warning"
      />
    </div>
  </div>
</template>

<script>
import Input from '@/components/Forms/Fields/Input'
import SwitchButton from '@/components/Forms/Fields/SwitchButton'
import Button from '@/components/Button'

export default {
  name: 'PrescriberForm',
  components: {
    Input, SwitchButton, Button
  },
  props: ['prescriber']
}
</script>

<style lang="scss" scoped>
.form{
  border-bottom: 1px solid $light-grey-color;

  .fields{
    flex-grow: 1;

    .row{
      & > div{
        flex-grow: 1;
      }
    }
  }

  .buttons{
    flex-grow: 1;
    justify-content: center;
    align-items: center;

    & > div{
      margin: 0;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}
</style>
