<template>
  <div class="field" :class="{vertical: orientation === 'vertical'}">
    <p @click="toggle">{{ title }}</p>

    <div
      class="switchButton"
      :class="{enabled: enable}"
      @click="toggle"
      ref="switch"
    >
      <div :class="`button ${enable ? 'enable' : ''}`" ref="button" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwitchButton',
  props: {
    title: String,
    value: { type: Boolean, default: false },
    orientation: { type: String, default: 'horizontal' }
  },
  data () {
    return {
      enable: this.value
    }
  },
  methods: {
    toggle () {
      this.enable = !this.enable
      this.$emit('input', this.enable)
      this.$emit('change', this.enable)
    }
  },
  watch: {
    value (enabled) {
      this.enable = enabled
    }
  }
}
</script>

<style lang="scss" scoped>
$height: 25px;

.field{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
  margin-bottom: 10px;

  p{
    font-size: 1.2rem;
    font-weight: 420;
  }

  .switchButton {
    position: relative;
    width: $height * 1.75;
    border-radius: 16px;
    background-color: $light-background-color;
    height: $height;
    margin-left: 16px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: all 0.25s;

    &.enabled{
      background-color: $main-color;
    }
    
    .button {
      position: absolute;
      width: $height;
      height: 100%;
      top: 0;
      left: -1px;
      border-radius: 16px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      background-color: $white-color;
      transition: 0.3s;

      &.enable {
        left: calc(100% - #{$height} + 1px);
      }
    }
  }

  &.vertical{
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0px;

    .switchButton {
      margin-left: 0px;
      margin-top: 16px;
    }
  }
}
</style>
